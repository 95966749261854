import React from 'react';
import styles from './footer.module.css';
import { useTranslation } from 'react-i18next';
import FacebookIcon from '../icons/FacebookIcon';
import LinkedIn from '../icons/LinkedinIcon';
import EmailIcon from '../icons/EmailIcon';
import PhoneIcon from '../icons/PhoneIcon';
import SadeemWhiteLogo from '../icons/SadeemWhiteLogo';
import TwitterIcon from '../icons/TwitterIcon';
import LocationIcon from '../icons/LocationIcon';
const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className={styles.footer}>
      <div className={`container ${styles.footer_container}`}>
        <div className={styles.logo_wrapper}>
          {/* <img
            src="/images/sadeem_logo.webp"
            width="64px"
            height="64px"
            alt="sadeem_tech"
          /> */}
          <SadeemWhiteLogo />
          {/* <p>{t('footer.about')}</p> */}
        </div>
        <div className={styles.links}>
          <h3>{t('footer.links')}</h3>
          <ul>
            <li>
              <a href="#about">{t('nav.about')}</a>
            </li>
            <li>
              <a href="#services">{t('nav.services')}</a>
            </li>
            <li>
              <a href="#clients">{t('nav.clients')}</a>
            </li>
            <li>
              <a href="#clients">{t('nav.news')}</a>
            </li>
          </ul>
        </div>
        <div className={styles.links}>
          <h3>{t('footer.contact_us')}</h3>
          <ul>
            <li>
              <a href="https://www.facebook.com/SadeemTech" target="_blank">
                <FacebookIcon width="24px" height="24px" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/sadeemtech/"
                target="_blank"
              >
                <LinkedIn width="24px" height="24px" />
              </a>
            </li>
            <li>
              <a href="https://x.com/sadeemTech" target="_blank">
                <TwitterIcon width="24px" height="24px" />
              </a>
            </li>
            <li>
              <a href="mailto:info@sadeem-tech.com">
                <EmailIcon width="24px" height="24px" />
              </a>
            </li>
            <li>
              <a href="tel:218910024433">
                <PhoneIcon width="24px" height="24px" />
              </a>
            </li>
          </ul>
          <a
            href="https://maps.app.goo.gl/PDAfC7G54RmvqiXg8 "
            className={styles.location}
            target="_blank"
          >
            <LocationIcon width="32px" height="32px" />
            <span>{t('footer.location')}</span>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
