import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// resources
import { enTranslations } from './en';
import { arTranslations } from './ar';
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  // .use(LanguageDetector)
  .init({
    lng: 'ar',
    fallbackLng: 'ar',
    resources: {
      ar: arTranslations,
      en: enTranslations,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
export default i18n;
