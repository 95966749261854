import React from 'react';

const LocationIcon = ({
  width = '32px',
  height = '32px',
  color = 'var(--primary-3)',
}) => {
  return (
    <>
      <svg
        viewBox="0 0 24 24"
        width={width}
        height={height}
        fill={'transparent'}
        color={'#00000000'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 21C15.5 17.4 19 14.1764 19 10.2C19 6.22355 15.866 3 12 3C8.13401 3 5 6.22355 5 10.2C5 14.1764 8.5 17.4 12 21Z"
          strokeWidth={2}
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
          stroke={color}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default LocationIcon;
